const idPortalApi = "9ebed8f3cee1dafe725d5dd4f1e0b956a92d92cd";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.senadoralexandrecosta.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "senadoralexandrecosta.ma.gov.br",
  NomePortal: "Senador Alexandre Costa",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/Rx2SsDmCdadDSRoa6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31784.12457446282!2d-44.0623942!3d-5.2602183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x788c15049534883%3A0xdbe9bf24f04bae6b!2sSen.%20Alexandre%20Costa%2C%20MA%2C%2065783-000!5e0!3m2!1spt-BR!2sbr!4v1722427362339!5m2!1spt-BR!2sbr",
};
